@use 'variables' as *;

@import 'libs/shared/ui/src/lib/assets/fonts/switzer.css';
@import 'reset-css/reset.css';
@import '@angular/cdk/overlay-prebuilt.css';

html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100vh;
  background-color: $color-background-default;
  overflow-x: hidden;
}

body,
input,
textarea {
  font-family: Switzer, sans-serif;
  font-size: 14px;
}
